<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Contenidos - Blog</template>

        <button v-if="canPost" class="btn btn-block btn-medium" @click="createItem">
          Nuevo Contenido
        </button>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :filters="vTable.filters"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay contenidos para mostrar` : `No tienes permisos para ver contenidos`"
          @changed="getItems"
          @onEditItem="onEditItem"
          @onDeleteItem="deleteItem"
          @onShowItem="onShowItem"
        />
      </Widget>
    </div>

    <ContenidoModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import ContenidoModal from "./ContenidoModal.vue";
import {can} from '@/acl/methods'

export default {
  components: {
    Widget,
    ContenidoModal,
  },
  data: () => ({
    vTable: {
      filters:[{id_tipo: 2}],
      headers: [
        {
          title: "titulo",
          mask: "Titulo",
          sortable: true,
        },
        {
          title: 'tipo_contenido.nombre',
          mask: "Tipo",
           sortable: true,
        },
        {
          title: "categoria.nombre",
          mask: "Categoría",
           sortable: true,
        },
        {
          title: "created_at",
          mask: "Creado",
          dateFormat: "DD-MM-YYYY HH:mm",
          sortable: true,
        },
        {
          title: "updated_at",
          mask: "Actualizado",
          dateFormat: "DD-MM-YYYY HH:mm",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-warning",
          callback: "onEditItem",
          tooltip: "Editar Contenido",
          icon: "fa-solid fa-pencil",
        },
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Contenido",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar Noticia",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.contenidos1;
    },
    canGet(){
      return can(this.$store.getters.user, 'contenidos', ['get'])
    },
    canPost(){
      return can(this.$store.getters.user, 'contenidos', ['post'])
    },
    canPut(){
      return can(this.$store.getters.user, 'contenidos', ['put'])
    },
    canDel(){
      return can(this.$store.getters.user, 'contenidos', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canPut) {
        actions.splice(actions.findIndex(a => a.callback === 'onEditItem'), 1)
      }
      if (this.canPut){
        actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      }
      if (!this.canDel) {
        actions.splice(actions.findIndex(a => a.callback === 'onDeleteItem'), 1)
      }
      return actions
    }
  },
  methods: {
    // buscadorFake(val){
    //   this.items.data = this.items.data.filter((item) => {
    //       if (item?.titulo?.includes(val?.toUpperCase()) || item?.tipo_contenido?.nombre.includes(val?.toUpperCase()) || item?.titulo?.includes(val?.toLowerCase()) || item?.tipo_contenido?.nombre.includes(val?.toLowerCase()) || item?.titulo?.includes(val?.charAt(0).toUpperCase() + val?.slice(1).toLowerCase()) || item?.tipo_contenido?.nombre.includes(val?.charAt(0).toUpperCase() + val?.slice(1).toLowerCase()) ) {return item}
    //     });

    // },
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
      this.initializeTable();
    },
    getItems(params) {
      this.$store.dispatch("getContenidos1", params);
    },
    onEditItem(item) {
      this.showModal = true;
      this.selItem = {...item, isShowing: false};
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = {...item, isShowing: true};
    },
    createItem() {
      this.showModal = true;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Cotenido", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteContenido", item.id).finally(() => {
            this.onSaveItem();
          });
        })
    },
  },
};
</script>
